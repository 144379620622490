import Container from 'react-bootstrap/Container'; // Importing Container component from react-bootstrap
import Row from 'react-bootstrap/Row'; // Importing Row component from react-bootstrap
import Col from 'react-bootstrap/Col'; // Importing Col component from react-bootstrap
import { NavLink } from 'react-router-dom'; // Importing NavLink from react-router-dom for navigation
import '../Blogs/Blog.css'; // Importing CSS file for styling
import BlogImg1 from '../Images/image1.webp'; // Importing blog image
import BlogImg2 from '../Images/blod2img2.jpeg'; // Importing blog image
import BlogImg3 from '../Images/Blog3-1.jpg'; // Importing blog image
const Blog = () => {
  return (
    <>
      <Container>
        <Row>
          <Col xxxl={4} xxl={4} xl={4} lg={4} md={6} sm={6}>{/* Defining a column with responsive grid spans */}
            <div className='Blog_Container'>
              <img src={BlogImg1} alt='1BHK Service Apartments' className='Blog_img'/>{/* Displaying the blog image */}
              <div className='Blog_Container_Infor'>
                <b><strog>1BHK Service Apartments</strog></b>{/* Blog title */}
                <br/><br/>
                <p>
                  The <strog>1BHK apartments</strog> at <strog>Mountforest</strog> are a sanctuary of comfort and convenience, providing all the amenities you need for a relaxing stay...
                </p>{/* Blog description */}
                <button className='Blog_btns'>
                  <NavLink to='/1BHK_Service_Apartments_Nathuakhan' className='Blog_link'>Read More...</NavLink>{/* "Read More" button with navigation link */}
                </button>
              </div>
            </div>
          </Col>
          <Col xxxl={4} xxl={4} xl={4} lg={4} md={6} sm={6}>{/* Defining a column with responsive grid spans */}
            <div className='Blog_Container'>
              <img src={BlogImg2} alt='1BHK Service Apartments' className='Blog_img'/>{/* Displaying the blog image */}
              <div className='Blog_Container_Infor'>
                <b><strog>Villas and Apartments at Mukteshwar</strog></b>{/* Blog title */}
                <br/><br/>
                <p>
                  <strog>Mukteshwar</strog>, a serene <strog>hill station</strog> nestled in the <strog>Kumaon hills of Uttarakhand</strog>, is fast becoming a coveted destination for...
                </p>{/* Blog description */}
                <button className='Blog_btns'>
                  <NavLink to='/Villas_and_Apartments_at_Mukteshwar' className='Blog_link'>Read More...</NavLink>{/* "Read More" button with navigation link */}
                </button>
              </div>
            </div>
          </Col>

          <Col xxxl={4} xxl={4} xl={4} lg={4} md={6} sm={6}>{/* Defining a column with responsive grid spans */}
            <div className='Blog_Container'>
              <img src={BlogImg3} alt='1BHK Service Apartments' className='Blog_img'/>{/* Displaying the blog image */}
              <div className='Blog_Container_Infor'>
                <b><strog>Luxurious Mountain Property with a Second Income Source</strog></b>{/* Blog title */}
                <br/><br/>
                <p>
                  MountForest's location is another major selling point. Positioned within close proximity to major cities while still offering the seclusion...</p>{/* Blog description */}
                <button className='Blog_btns'>
                  <NavLink to='/Luxurious_Mountain_Property_with_a_Second_Income_Source' className='Blog_link'>Read More...</NavLink>{/* "Read More" button with navigation link */}
                </button>
              </div>
            </div>
          </Col>
          
        </Row>
        <br/>
        <Row>
          <Col>
            <center>
              <button className='Blog_btns'>
                <NavLink to='/blog' className='Blog_link'>More Blogs</NavLink>{/* "Read More" button with navigation link */}
              </button>
            </center>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Blog; // Exporting the Blog component for use in other parts of the application
