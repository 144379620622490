import Navbar from './Components/Navbar/Navbar';
import Home from './Components/Home/Home';
import CasaMelo from './Components/Casamelo/Casamelo';
import TECHSPECIFICATIONS from './Components/Casamelo/TECHSPECIFICATIONS';
import Gallery from './Components/Gallery/Gallery';
import Blog from './Components/Blogs/Blog';
import FirstBlog from './Components/Blogs/FirstBlog';
import Contact from './Components/Contact/Contact';
import About from './Components/About/About';
import Footer from './Components/Footer/Footer';
import CallNow from './Components/CallNow/CallNow';
import NotFound from './Components/NotFound/NotFound';
import { BrowserRouter, Routes, Route } from 'react-router-dom'; // Importing necessary components for routing
import './App.css'; // Importing the CSS file for styling the App component
import ScrollToTop from './Components/ScrollTop';
import Blog2 from './Components/Blogs/Blog2';
import Blog3 from './Components/Blogs/Blog3';
import Blog4 from './Components/Blogs/Blogs4';

const App = () => {
  return (
    <>
      <BrowserRouter> {/* Setting up the router for navigation */}
        <ScrollToTop/>
        <Navbar /> {/* Rendering the Navbar component */}
        <Routes> {/* Defining the routes for the application */}
          <Route index element={<Home />} /> {/* Default route rendering the Home component */}
          <Route path='/home' element={<Home />} /> {/* Route for the Home component */}
          <Route path='/casamelo' element={<CasaMelo />} /> {/* Route for the CasaMelo component */}
          <Route path='/TECHSPECIFICATIONS' element={<TECHSPECIFICATIONS />} /> {/* Route for the TECHSPECIFICATIONS component */}
          <Route path='/gallery' element={<Gallery />} /> {/* Route for the Gallery component */}
          <Route path='/contact' element={<Contact />} /> {/* Route for the Contact component */}
          <Route path='/about' element={<About />} /> {/* Route for the About component */}
          <Route path='/blog' element={<Blog />} /> {/* Route for the Blog component */}
          <Route path='/1BHK_Service_Apartments_Nathuakhan' element={<FirstBlog />} /> {/* Route for the FirstBlog component */}
          <Route path='/Villas_and_Apartments_at_Mukteshwar' element={<Blog2/>} />
          <Route path='/Luxurious_Mountain_Property_with_a_Second_Income_Source' element={<Blog3/>} />
          <Route path='/Your_Second_Home_Awaits' element={<Blog4/>} />
          <Route path='*' element={<NotFound />} /> {/* Catch-all route for 404, rendering the NotFound component */}
        </Routes>
      </BrowserRouter>
      <Footer /> {/* Rendering the Footer component */}
      <CallNow /> {/* Rendering the CallNow component */}
    </>
  );
};

export default App; // Exporting the App component as the default export
