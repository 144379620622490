import Container from 'react-bootstrap/Container'; // Importing Container component from react-bootstrap
import Row from 'react-bootstrap/Row'; // Importing Row component from react-bootstrap
import Col from 'react-bootstrap/Col'; // Importing Col component from react-bootstrap
import { NavLink } from 'react-router-dom'; // Importing NavLink from react-router-dom for navigation
import './Blog.css'; // Importing CSS file for styling
import Fixappoitment from '../Fixappoitment/Fixappoitment'; // Importing Fixappoitment component
import FooterAboveForm from '../FooterAboveForm/FooterAboveForm'; // Importing FooterAboveForm component
import BlogImg1 from '../Images/image1.webp'; // Importing blog image
import BlogImg2 from '../Images/blod2img2.jpeg'; // Importing blog image
import BlogImg3 from '../Images/Blog3-1.jpg'; // Importing blog image
import { Helmet } from "react-helmet"; // Importing Helmet for managing document head

const Blog = () => {
  return (
    <>
      <Container>
        <Helmet>
          <meta charset="utf-8"/> 
           {/* Setting character set for the document */}
          <meta name="viewport" content="width=device-width, initial-scale=1"/> 
           {/* Setting viewport for responsive design */}
          <title>Blog | Mountforest Promoters & Developer | Luxurious Homes | Real Estate</title> {/* Setting the document title */}
          <meta name="description" content="Discover your dream home with Mount Forest, your trusted partner in India's vibrant property market. From luxurious apartments in mountain, we offer a diverse range of properties tailored to your needs and preferences. Our experienced team of professionals guides you through every step of the buying process, ensuring a seamless and rewarding experience. Explore our portfolio today and embark on your journey towards finding the perfect home in India."/>
           {/* Setting the meta description for SEO */}
          <meta name="keywords" content="Mount Forest,real estate,blog"/>
          <link rel="canonical" href="https://www.mountforest.in/blog" />
        </Helmet>
        <Row>
          <Col xxxl={4} xxl={4} xl={4} lg={4} md={6} sm={6}>{/* Defining a column with responsive grid spans */}
            <div className='Blog_Container'>
              <img src={BlogImg1} alt='1BHK Service Apartments' className='Blog_img'/>{/* Displaying the blog image */}
              <div className='Blog_Container_Infor'>
                <b>1BHK Service Apartments</b>{/* Blog title */}
                <br/><br/>
                <p>
                  The 1BHK apartments at Mount Forest are a sanctuary of comfort and convenience, providing all the amenities you need for a relaxing stay.
                </p>{/* Blog description */}
                <button className='Blog_btns'>
                  <NavLink to='/1BHK_Service_Apartments_Nathuakhan' className='Blog_link'>Read More...</NavLink>{/* "Read More" button with navigation link */}
                </button>
              </div>
            </div>
          </Col>
          <Col xxxl={4} xxl={4} xl={4} lg={4} md={6} sm={6}>{/* Defining a column with responsive grid spans */}
            <div className='Blog_Container'>
              <img src={BlogImg2} alt='1BHK Service Apartments' className='Blog_img'/>{/* Displaying the blog image */}
              <div className='Blog_Container_Infor'>
                <b>Villas and Apartments at Mukteshwar</b>{/* Blog title */}
                <br/><br/>
                <p>
                  Mukteshwar, a serene hill station nestled in the Kumaon hills of Uttarakhand, is fast becoming a coveted destination for
                </p>{/* Blog description */}
                <button className='Blog_btns'>
                  <NavLink to='/Villas_and_Apartments_at_Mukteshwar' className='Blog_link'>Read More...</NavLink>{/* "Read More" button with navigation link */}
                </button>
              </div>
            </div>
          </Col>
          <Col xxxl={4} xxl={4} xl={4} lg={4} md={6} sm={6}>{/* Defining a column with responsive grid spans */}
            <div className='Blog_Container'>
              <img src={BlogImg3} alt='1BHK Service Apartments' className='Blog_img'/>{/* Displaying the blog image */}
              <div className='Blog_Container_Infor'>
                <b>Luxurious Mountain Property with a Second Income Source</b>{/* Blog title */}
                <br/><br/>
                <p>
                  Mukteshwar, a serene hill station nestled in the Kumaon hills of Uttarakhand, is fast becoming a coveted destination for
                </p>{/* Blog description */}
                <button className='Blog_btns'>
                  <NavLink to='/Luxurious_Mountain_Property_with_a_Second_Income_Source' className='Blog_link'>Read More...</NavLink>{/* "Read More" button with navigation link */}
                </button>
              </div>
            </div>
          </Col>
          <Col xxxl={4} xxl={4} xl={4} lg={4} md={6} sm={6}>{/* Defining a column with responsive grid spans */}
            <div className='Blog_Container'>
              <img src={BlogImg3} alt='1BHK Service Apartments' className='Blog_img'/>{/* Displaying the blog image */}
              <div className='Blog_Container_Infor'>
                <b>Your Second Home Awaits</b>{/* Blog title */}
                <br/><br/>
                <p>
                  Discover the luxury of owning a second home at Mountforest's Casamello in Mukteshwar. Explore the perfect blend of tranquility and investment potential in the heart of nature
                </p>{/* Blog description */}
                <button className='Blog_btns'>
                  <NavLink to='/Your_Second_Home_Awaits' className='Blog_link'>Read More...</NavLink>{/* "Read More" button with navigation link */}
                </button>
              </div>
            </div>
          </Col>
          
        </Row>
      </Container>
      <Fixappoitment/>{/* Rendering the Fixappoitment component   */}
      <FooterAboveForm/>{/* Rendering the FooterAboveForm component */}
    </>
  )
}

export default Blog; // Exporting the Blog component for use in other parts of the application
