import React, { Component } from 'react';
import './Navbar.css'; // Importing the CSS file for styling the Navbar component
import Logo from '../Images/logo3.png'; // Importing the logo image
import { HiMiniChevronDown, HiMiniChevronRight } from "react-icons/hi2"; // Importing icons from the react-icons library
import { NavLink } from 'react-router-dom';

// Defining the Navbar component which extends from React.Component
class Navbar extends Component {
    // Defining the initial state of the component
    state = {
        clicked: false, // State to manage the mobile menu toggle
        scrolled: false // State to manage the scroll status for changing header styles
    };

    // Method to handle the click event on the mobile menu icon
    handleClick = () => {
        this.setState({ clicked: !this.state.clicked }); // Toggling the clicked state
    }

    // Method to handle the click event on navigation links
    handleNavLinkClick = () => {
        this.setState({ clicked: false }); // Setting clicked state to false to close the mobile menu
    }

    // Method to handle the scroll event and update the scrolled state
    handleScroll = () => {
        if (window.scrollY > 600) { // Checking if the scroll position is greater than 600px
            this.setState({ scrolled: true }); // Setting scrolled state to true
        } else {
            this.setState({ scrolled: false }); // Setting scrolled state to false
        }
    }

    // Lifecycle method that runs after the component has been mounted
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll); // Adding NavLink scroll event listener to the window
    }

    // Lifecycle method that runs before the component is unmounted
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll); // Removing the scroll event listener from the window
    }

    // Render method to define the JSX to be rendered
    render() {
        return (
            <header className={this.state.scrolled ? 'scrolled' : ''}> {/* Adding 'scrolled' class based on the state */}
                <NavLink to='/Home' id='Logo_link'>
                    <img src={Logo} alt='' className='Logo' /> {/* Rendering the logo image */}
                </NavLink>
                <nav id='navbar' className={this.state.clicked ? '#navbar active' : '#navbar'}> {/* Toggling active class based on the clicked state */}
                    <li><abbr><NavLink to='/Home' className='navlink' onClick={this.handleNavLinkClick}>Home</NavLink></abbr></li>
                    <li><abbr><NavLink to='/About' className='navlink' onClick={this.handleNavLinkClick}>About Us</NavLink></abbr></li>
                    <li>
                        <abbr>Project <HiMiniChevronDown /></abbr> {/* Rendering NavLink dropdown menu with NavLink nested submenu */}
                        <ul className="dropdown">
                            <li>
                                <abbr>On Going<HiMiniChevronRight /></abbr>
                                <ul className='sub-dropdown'>
                                    <li><abbr><NavLink to='/casamelo' className='navlink' onClick={this.handleNavLinkClick}>Casa Melo</NavLink></abbr></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <abbr>Media <HiMiniChevronDown /></abbr> {/* Rendering another dropdown menu */}
                        <ul className="dropdown">
                            <li><abbr><NavLink to='/gallery' className='navlink' onClick={this.handleNavLinkClick}>Gallery</NavLink></abbr></li>
                            <li><abbr><NavLink to='/blog' className='navlink' onClick={this.handleNavLinkClick}>Blogs</NavLink></abbr></li>
                        </ul>
                    </li>
                    <li><abbr><NavLink to='/contact' className='navlink' onClick={this.handleNavLinkClick}>Contact Us</NavLink></abbr></li>
                </nav>
                <div id='moblie' onClick={this.handleClick}> {/* Rendering the mobile menu icon */}
                    <i id='bar' className={this.state.clicked ? 'fas fa-times' : 'fas fa-bars'}></i> {/* Toggling between 'times' and 'bars' icons based on the clicked state */}
                </div>
            </header>
        );
    }
}

export default Navbar; // Exporting the Navbar component for use in other parts of the application
