import React, { useEffect } from 'react';
import FrontIMg from '../Images/logo.webp'; // Importing the logo image
import './frontpage.css'; // Importing the CSS file for styling

const FrontPage = () => {
  useEffect(() => {
    // Effect hook to run when component mounts
    const timer = setTimeout(() => {
      // Timer to hide the front page after 3 seconds (3000 milliseconds)
      document.querySelector('.front-page').style.display = 'none';
    }, 3000);

    return () => clearTimeout(timer); // Cleanup function to clear the timer on unmount
  }, []); // Empty dependency array ensures effect runs only once on mount

  return (
    <main>
      {/* Main content of the component */}
      <div className="front-page">
        {/* Div containing the front page content */}
        <div className="image-container">
          {/* Div for image container */}
          <img src={FrontIMg} alt="Logo" className="Mountforest"/>
          {/* Image element displaying the logo with specified alt text and class name */}
        </div>
      </div>
    </main>
  );
};

export default FrontPage;
