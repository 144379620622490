import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GalleryImg1 from '../Images/GalleryImg1.webp';
import GalleryImg2 from '../Images/GalleryImg2.webp';
import GalleryImg3 from '../Images/GalleryImg3.webp';
import GalleryImg4 from '../Images/GalleryImg4.webp';
import GalleryImg5 from '../Images/GalleryImg5.webp';
import GalleryImg6 from '../Images/GalleryImg6.webp';
import GalleryImg7 from '../Images/GalleryImg7.webp';
import GalleryImg8 from '../Images/GalleryImg8.webp';
import GalleryImg9 from '../Images/GalleryImg9.webp';
import GalleryImg10 from '../Images/GalleryImg10.webp';
import GalleryImg11 from '../Images/GalleryImg11.webp';
import GalleryImg12 from '../Images/GalleryImg12.webp';
import GalleryImg13 from '../Images/GalleryImg13.jpeg';
import GalleryImg15 from '../Images/GalleryImg15.jpeg';
import GalleryImg16 from '../Images/GalleryImg16.jpeg';
import GalleryImg17 from '../Images/GalleryImg17.jpeg';
import GalleryImg18 from '../Images/GalleryImg18.jpeg';
import GalleryImg19 from '../Images/GalleryImg19.jpeg';
import GalleryImg20 from '../Images/GalleryImg20.jpeg';
import GalleryImg21 from '../Images/GalleryImg21.jpeg';
import GalleryImg22 from '../Images/GalleryImg22.jpeg';
// import GalleryImg23 from '../Images/GalleryImg23.jpeg';
import GalleryImg24 from '../Images/GalleryImg24.jpeg';
import GalleryImg25 from '../Images/GalleryImg25.jpeg';
import './Gallery.css';
import Fixappoitment from '../Fixappoitment/Fixappoitment';
import FooterAboveForm from '../FooterAboveForm/FooterAboveForm';
import { Helmet } from "react-helmet";
import GalleryVideo1 from '../Video/galleryVideo1.mp4'
import GalleryVideo2 from '../Video/galleryVideo2.mp4'
import GalleryVideo3 from '../Video/galleryVideo3.mp4'



const images = [
  { src: GalleryImg20, date: 'August-2024', description: 'Image 1 description' },
  { src: GalleryImg21, date: 'August-2024', description: 'Image 1 description' },
  { src: GalleryImg22, date: 'August-2024', description: 'Image 1 description' },
  // { src: GalleryImg23, date: 'August-2024', description: 'Image 1 description' },
  { src: GalleryImg24, date: 'August-2024', description: 'Image 1 description' },
  { src: GalleryImg25, date: 'August-2024', description: 'Image 1 description' },
  { src: GalleryImg13, date: 'July-2024', description: 'Image 1 description' },
  { src: GalleryImg15, date: 'July-2024', description: 'Image 3 description' },
  { src: GalleryImg16, date: 'July-2024', description: 'Image 4 description' },
  { src: GalleryImg17, date: 'July-2024', description: 'Image 5 description' },
  { src: GalleryImg18, date: 'July-2024', description: 'Image 6 description' },
  { src: GalleryImg19, date: 'July-2024', description: 'Image 7 description' },
  { src: GalleryImg1, date: 'April-2024', description: 'Image 1 description' },
  { src: GalleryImg2, date: 'April-2024', description: 'Image 2 description' },
  { src: GalleryImg3, date: 'April-2024', description: 'Image 3 description' },
  { src: GalleryImg4, date: 'April-2024', description: 'Image 4 description' },
  { src: GalleryImg5, date: 'April-2024', description: 'Image 5 description' },
  { src: GalleryImg6, date: 'April-2024', description: 'Image 6 description' },
  { src: GalleryImg7, date: 'April-2024', description: 'Image 7 description' },
  { src: GalleryImg8, date: 'April-2024', description: 'Image 8 description' },
  { src: GalleryImg9, date: 'April-2024', description: 'Image 9 description' },
  { src: GalleryImg10, date: 'April-2024', description: 'Image 10 description' },
  { src: GalleryImg11, date: 'April-2024', description: 'Image 11 description' },
  { src: GalleryImg12, date: 'April-2024', description: 'Image 12 description' }
];

const Gallery = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const closeModal = (e) => {
    if (e.target.classList.contains('modal')) {
      setSelectedImage(null);
    }
  };

  return (
    <main>
      <Helmet>
        <meta charSet="utf-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1"/>
        <title>Mountforest Gallery | Real Estate</title>
        <meta name="description" content="Experience the ideal weekend break or holiday homes retreat set amidst breathtaking mountains and lush natural surroundings with MountForest. Whether you want the tranquility of nature or the opulence of serviced apartments and luxury villas with spectacular mountain and Himalayan views, MountForest is your ultimate destination to fulfill your dream homes."/> 
        <meta name="keywords" content="mountforest, real estate company, service apartment, luxury villas, holiday homes, second homes hills, Himalayan views, properties, property, Second Income Source, Property in uttarakhand" />
      </Helmet>
      <h1 className='gallery_header'>Construction Work In Progress</h1>
      <p className='gallery_prea'>
        Welcome growth Partners, Here you can track the progress of our ongoing construction. We regularly upload images to give you a visual update on how far our work has progressed. Stay informed and watch as our projects take shape, step by step. Your interest and support are greatly appreciated as we strive to complete our work efficiently and effectively. Feel free to check back often for the latest updates and milestones. Thank you for following our journey and being a part of Mount Forest's growth and development.
      </p>
      <Container fluid>
        <Row>
          <Col>
            <h2 className='Gallery_Video_image_head'>Video</h2>
          </Col>
        </Row>
        <Row>
          <Col xxxl={4} xxl={4} xl={4} lg={4} md={6} sm={6} xs={6}>
            <div className='video_Container'>
              <video  className="Gallery_Video" controls>
                <source src={GalleryVideo1} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </Col>
          <Col xxxl={4} xxl={4} xl={4} lg={4} md={6} sm={6} xs={6}>
            <div className='video_Container'>
              <video  className="Gallery_Video" controls>
                <source src={GalleryVideo2} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </Col>
          <Col xxxl={4} xxl={4} xl={4} lg={4} md={6} sm={6} xs={6}>
            <div className='video_Container'>
              <video  className="Gallery_Video"  controls>
                <source src={GalleryVideo3} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2 className='Gallery_Video_image_head'>Images</h2>
          </Col>
        </Row>
        <br/>
        <br/>
        <Row>
          {images.map((image, index) => (
            <Col key={index} xxxl={4} xxl={4} xl={4} lg={4} md={6} sm={6} xs={6} className="gallery_col">
              <div className='Gallery_Container' onClick={() => handleImageClick(image)}>
                <img src={image.src} alt={`Property in Mountforest, April 2024`} className="Gallery_imgs" />
                <p className='Gallery_imgs_date'>{image.date}</p>
                {/* <p className='Gallery_imgs_description'>{image.description}</p> */}
              </div>
            </Col>
          ))}
        </Row>
      </Container>
      {selectedImage && (
        <div className="modal" onClick={closeModal}>
          <span className="close" onClick={() => setSelectedImage(null)}>&times;</span>
          <img className="modal-content" src={selectedImage.src} alt="Full Screen" />
        </div>
      )}
      <Fixappoitment /> 
      <FooterAboveForm/> 
    </main>
  );
};

export default Gallery;
