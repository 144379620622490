import Container from 'react-bootstrap/Container'; // Importing Container component from react-bootstrap
import Row from 'react-bootstrap/Row'; // Importing Row component from react-bootstrap
import Col from 'react-bootstrap/Col'; // Importing Col component from react-bootstrap
import Location1 from '../Images/Dehradun.webp'  // Importing image for Dehradun location
import Location2 from '../Images/nathukhan.webp' // Importing image for Nathukhan location
import Location3 from '../Images/ramgarh.webp'   // Importing image for Ramgarh location
import './Home.css';  // Importing CSS file for styling

const LocationImageHomePage = () => {
  return (
    <main>
        <div className='line-center'>
            <hr className='line-only'/>
            <p className="Home_Heading">Our Locations</p>{/* Heading for project section */}
            <hr className='line-only'/>
        </div>
        <p className='location_prea'>
            We offer ideal locations for your residence that is perfect for enjoying holidays or vacation. Experience the tranquility and beauty of our community, designed for relaxation and leisure.
        </p> {/* Brief description of the locations */}
        <section className="Location_Images_Container">
            <Container>
                <Row>
                    <Col xxxl={4} xxl={4} xl={4} lg={4} md={6} sm={6} className='Img-Name-Container'>
                        <img src={Location3} alt="Property In Location" className="Location_Image"/>{/* Image for Ramgarh location */}
                        <p className="Location_img_name">Dehradun</p> {/* Name for the location */}
                    </Col>
                    <Col xxxl={4} xxl={4} xl={4} lg={4} md={6} sm={6} className='Img-Name-Container'>
                        <img src={Location2} alt="Property In Location" className="Location_Image"/>{/* Image for Nathukhan location */}
                        <p className="Location_img_name">Nainital</p>{/* Name for the location */}
                    </Col>
                    <Col xxxl={4} xxl={4} xl={4} lg={4} md={6} sm={6} className='Img-Name-Container'>
                        <img src={Location1} alt="Property In Location" className="Location_Image"/>{/* Image for Dehradun location */}
                        <p className="Location_img_name">Rishikesh</p>{/* Name for the location */}
                    </Col>
                </Row>
            </Container>
        </section>
    </main>
  );
};

export default LocationImageHomePage;  // Exporting the component for use in other parts of the application
