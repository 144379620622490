import { useState, useEffect } from 'react'; // Importing useState and useEffect from React
import { BiSolidPhoneCall } from "react-icons/bi"; // Importing phone call icon from react-icons
import { FaWhatsapp } from "react-icons/fa"; // Importing WhatsApp icon from react-icons
import './CallNow.css'; // Importing CSS styles for CallNow component
import React from 'react'; // Importing React

const CallNow = () => { // Functional component for CallNow button
    const [isButtonVisible, setIsButtonVisible] = useState(false); // State to manage button visibility

    const whatsapp = () => {
        // Optional form visibility toggling logic can be added here
    };

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            setIsButtonVisible(scrollPosition >= 300);
        };

        window.addEventListener('scroll', handleScroll); // Adding scroll event listener to window
        return () => {
            window.removeEventListener('scroll', handleScroll); // Cleaning up event listener on component unmount
        };
    }, []);

    return React.createElement(
        React.Fragment, // Using React.Fragment to group multiple elements without adding extra nodes to the DOM
        null,
        React.createElement(
            'a',
            {
                href: "tel:7289000959", // Phone number link
                id: 'Call_Now_btn', // ID for styling
                rel: 'noopener noreferrer', // Security feature for external links
                className: isButtonVisible ? 'visible' : '' // Conditional class for visibility
            },
            React.createElement(BiSolidPhoneCall, null), // Phone call icon
            '\u00A0\u00A0\u00A0\u00A0Call Now' // Text displayed on the button
        ),
        React.createElement(
            'a',
            {
                onClick: whatsapp, // Function to handle click, currently empty
                href: "https://wa.me/917289000959", // WhatsApp link
                target: "_blank", // Opens the link in a new tab
                rel: "noopener noreferrer", // Security feature for external links
                'aria-label': "This is Mount Forest WhatsApp", // Accessibility feature
                title: "Mountforest WhatsApp", // Tooltip text
                className: `whatsapp_icon ${isButtonVisible ? 'visible' : ''}` // Conditional class for visibility
            },
            React.createElement(FaWhatsapp, null) // WhatsApp icon
        )
    );
}

export default CallNow; // Exporting CallNow component as default
