// Importing necessary dependencies and components
import React from 'react';
import TopSlider from './HomePageTopImgSlider';
import LocationImageHomePage from './LocationImageHomePage';
import SecondIncomeHomePage from './SecondIncomeHomePage';
import ProjectHomePage from './ProjectHomePage';
import BlogsHomePage from './BlogsHomePage';
import './Home.css';
import FrontPage from '../frontpage/FrontPage';
import Fixappoitment from '../Fixappoitment/Fixappoitment';
import TimerForm from '../TimerForm/TimerForm';
import FooterAboveForm from '../FooterAboveForm/FooterAboveForm';
import { Helmet } from "react-helmet";
import image from '../Images/Section7-img.webp'

// Functional component for the Home page
const Home = () => {
  return (
    <>
      {/* Helmet for managing SEO metadata */}
      
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "RealEstateAgent",
              "name": "Mountforest",
              "url": "https://www.mountforest.com",
              "description": "Discover luxury living with MountForest. Offering service apartments, villas, and holiday homes with breathtaking Himalayan views. Experience unparalleled opulence today.",
            })}
          </script>
            <meta charSet="utf-8"/>
            <meta name="viewport" content="width=device-width, initial-scale=1"/>
            <title>Property in mountain</title>
            <meta name="description" content="Discover luxury living with MountForest. Offering service apartments, villas, and holiday homes with breathtaking Himalayan views. Experience unparalleled opulence today."/>
            <meta name="keywords" content="MountForest,luxury living,real estate company,luxury villas,service apartments,holiday homes,second homes,Himalayan views"/>
        </Helmet>
      
      {/* Component for the front page */}
      <FrontPage/>
      {/* Component for the top slider */}
      <TopSlider/>
      
      {/* Heading and introductory paragraph */}
      <h1 className='Home_page_Heading'>Mountforest Promoters & Developer</h1>
      <p id='Home_page_Paragraph'>
        Welcome to  MountForest,  where were define  luxury living  amidst the breathtaking beauty of the Himalayas. As a leading real estate company , we specialize in offering unparalleled service apartments , luxury villas ,  holiday homes , and second homes  in the serene hills, complemented by majestic Himalayan views .<br/><br/>

        At MountForest , we understand the allure of finding the perfect retreat in nature's embrace. Our service apartments  epitomize comfort and convenience, providing a seamless blend of modern amenities and rustic charm. Nestled amidst verdant landscapes, each  apartment  offers a tranquil sanctuary to unwind and rejuvenate. Whether you're a solo traveler, a couple seeking a romantic escape, or a family looking for adventure, our service apartments  cater to all your requirements.

        For those desiring the epitome of  luxury living , our collection of exquisite  villas  is unparalleled. Boasting lavish interiors, panoramic views, and world-class facilities, our  villas   redefine opulence in the heart of nature. Eac  villa  is meticulously designed to offer privacy, exclusivity, and a sense of tranquility, creating an idyllic retreat for indulgence and relaxation.
        <br/><br/>
        <b>Looking for the perfect holiday home or retirement home?</b><br/><br/>
        Look no further than MountForest . Our portfolio features a diverse range o  properties , from cozy cottages to spaciou  estates , each offering a unique experience tailored to your preferences. Whether you envision cozy evenings by the fireplace or  adventurous days   exploring the surrounding hills, ou  holiday homes  are your gateway to unforgettable experiences.

        One of the highlights of ou  properties  is the unparalleled Himalayan views  they offer. Imagine waking up to the sight of snow-capped peaks, basking in the golden hues of sunrise, or stargazing under the vast expanse of the night sky. At MountForest , every moment is a masterpiece, with nature's grandeur serving as the backdrop to your extraordinary lifestyle.

        As a truste  real estate company , we are committed to exceeding your expectations at every step of your journey. From personalized assistance i  property  selection to seamless transaction processes, our team of experts is dedicated to ensuring your experience wit  MountForest  is nothing short of exceptional.<br/><br/>

        Experience the pinnacle of  luxury living  amidst th  Himalayas  with MountForest . Your dream home awaits in this enchanting paradise.
      </p>
      
      {/* Components for fixing appointments and timer form */}
      <Fixappoitment/>
      <TimerForm/>
      
      {/* Components for displaying location image, second income section, project section, and blogs */}
      <LocationImageHomePage/>
      <SecondIncomeHomePage/>
      <ProjectHomePage/>
      
      <div className='home_img2_container'>
        <img src={image} alt='real estate' id='home-image2'/>
        <div className='home-pra2'>
          <h3 className='text-color'>Explore the Beauty of Mountain Homes with us....</h3>
          <br/>
          <p className='text-color'>
            Discover the allure of  mountain living  with our exceptional selection of  second homes   holiday homes . Immerse yourself in the peace of nature and experience ultimate relaxation. Our team of experts is dedicated to providing the best services and ensuring your satisfaction. So you are heartily welcome in such an environment. Now let us tell you what Nature has prepared for you.
            <br/><br/>
            "Immerse yourself in the breath taking beauty o mountains  and experience nature at its finest. Discover the majestic peaks that touch the sky, clothed in blankets of lush green forests or shimmering white snow. Marvel at the grandeur of these natural wonders as you hike through winding trails, surrounded by the serene tranquility of the wilderness. Inhale the crisp mountain air, tinged with the scent of pine and wildflowers, and feel the invigorating rush of adrenaline as you conquer challenging ascents. Capture stunning vistas that stretch as far as the eye can see, and be mesmerized by the play of light and shadows that dance upon the rugged terrain. Let the mountains captivate your soul, as their timeless beauty leaves you awe-inspired and yearning for more."
          </p>
        </div>
      </div>

      <div className='line-center'>
        <hr className='line-only'/>
        <p className="Home_Heading">Blogs</p>{/* Heading for project section */}
        <hr className='line-only'/>
      </div>

      <BlogsHomePage/>

      
      <div className='line-center'>
        <hr className='line-only'/>
        <p className="Home_Heading">Casa Melo Virtual Video</p>{/* Heading for project section */}
        <hr className='line-only'/>
      </div>
      <div id='Youtube-contaner'> 
        <iframe src="https://www.youtube.com/embed/fJ6MDKNw5vs?si=d05QLC_--yBhjlIV" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen id='youtube-video'></iframe>
      </div>

      {/* Component for footer above form */}
      <FooterAboveForm/>
    </>
  );
};

export default Home;
