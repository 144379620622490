import React, { useState, useEffect } from 'react'; // Importing React hooks for state and effect management
import { ImLocation2 } from "react-icons/im"; // Importing location icon from react-icons
import { PiPhoneCallFill } from "react-icons/pi"; // Importing phone icon from react-icons
import { TbMailOpenedFilled } from "react-icons/tb"; // Importing email icon from react-icons
import './contact.css'; // Importing CSS file for styling
import { Helmet } from "react-helmet"; // Importing Helmet for managing document head
import AOS from 'aos'; // Importing AOS for animations

const Contact = () => {
  const [form, setForm] = useState({
    Name: '',
    Phone: '',
    Email: ''
  }); // State to manage form input values

  const [isButtonDisabled, setIsButtonDisabled] = useState(true); // State to manage submit button disable state

  useEffect(() => {
    const allFieldsFilled = Object.values(form).every(field => field.trim() !== '');
    setIsButtonDisabled(!allFieldsFilled);
  }, [form]); // Effect to check if all form fields are filled

  const handleFormChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  }; // Handler to update form state on input change

  const handleSubmitContact = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    Object.entries(form).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append("Date", new Date().toISOString().substring(0, 10));

    try {
      const response = await fetch("https://script.google.com/macros/s/AKfycbxOBg4SX2a7am5fybf5qNAfmMmmsDgwiZk2nZ_jxh_N-5hBTLAX7yH7MY1_YMSkhjU/exec", {
        method: "POST",
        body: formData
      });
      if (response.ok) {
        alert('Your Form successfully submitted');
        setForm({ Name: '', Phone: '', Email: '' });
      } else {
        alert('Form submission failed');
      }
    } catch (error) {
      alert('Your Form successfully submitted');
    }
  }; // Handler to submit the form data to the Google Apps Script

  useEffect(() => {
    AOS.init({
      duration: 2000
    });
  }, []); // Initializing AOS for animations

  return React.createElement(
    React.Fragment,
    null,
    React.createElement(
      Helmet,
      null,
      React.createElement("meta", { charSet: "utf-8" }),
      React.createElement("meta", { name: "viewport", content: "width=device-width, initial-scale=1" }),
      React.createElement("title", null, "Contact Us | MountForest | Real Estate"),
      React.createElement("meta", { name: "description", content: "Contact Us Mountforest Promoters & Developer is a real estate and construction consulting company" }),
      React.createElement("meta", { name: "keywords", content: "mountforest, real estate company, service apartment, luxury villas, holiday homes, second homes hills, Himalayan views, properties, property, Second Income Source" }),
      
    ), // Setting up meta tags and title for the page
    React.createElement(
      'section',
      { className: 'contact_page_container' },
      React.createElement(
        'div',
        { id: 'contact_page_container_background_box' },
        React.createElement(
          'div',
          { className: "contact_page_info_container", "data-aos": "fade-right" },
          React.createElement(
            'table',
            null,
            React.createElement(
              'tbody',
              null,
              React.createElement(
                'tr',
                null,
                React.createElement(
                  'td',
                  { className: "contact_page_icon" },
                  React.createElement(ImLocation2, { "aria-label": "Location Icon" })
                ),
                React.createElement(
                  'td',
                  null,
                  React.createElement('b', { className: "contact_page_info" }, "Address"),
                  React.createElement('p', { className: "contact_page_info" }, "D-13, 4th floor, Sector 3, Noida Uttar Pradesh 201301")
                )
              ), // Displaying address information with icon
              React.createElement(
                'tr',
                null,
                React.createElement(
                  'td',
                  { className: "contact_page_icon" },
                  React.createElement(PiPhoneCallFill, { "aria-label": "Phone Icon" })
                ),
                React.createElement(
                  'td',
                  null,
                  React.createElement('b', { className: "contact_page_info" }, "Phone"),
                  React.createElement('p', { className: "contact_page_info" }, "0120-4124076,", React.createElement('br', null), "+91 7289000959")
                )
              ), // Displaying phone information with icon
              React.createElement(
                'tr',
                null,
                React.createElement(
                  'td',
                  { className: "contact_page_icon" },
                  React.createElement(TbMailOpenedFilled, { "aria-label": "Email Icon" })
                ),
                React.createElement(
                  'td',
                  null,
                  React.createElement('b', { className: "contact_page_info" }, "Email"),
                  React.createElement('p', { className: "contact_page_info" }, "Query@mountforest.in")
                )
              ) // Displaying email information with icon
            )
          )
        ),
        React.createElement(
          'div',
          { className: "contact_page_form_container", "data-aos": "fade-left" },
          React.createElement(
            'form',
            { onSubmit: handleSubmitContact },
            React.createElement('h1', { id: 'contact_page_heading' }, "Contact"),
            React.createElement('input', {
              type: "hidden",
              name: "Date",
              value: new Date().toISOString().substring(0, 10),
              readOnly: true
            }), // Hidden input for the date
            React.createElement(
              'div',
              { className: "contact_page_form_input_box" },
              React.createElement('input', {
                type: "text",
                placeholder: "Enter Name",
                required: true,
                minLength: 3,
                maxLength: 30,
                name: 'Name',
                pattern: "^[a-zA-Z]*$",
                title: "Only letters and spaces allowed",
                value: form.Name,
                onChange: handleFormChange
              }) // Input for name with validation
            ),
            React.createElement(
              'div',
              { className: "contact_page_form_input_box" },
              React.createElement('input', {
                type: "tel",
                placeholder: "Enter Phone",
                required: true,
                pattern: "^\\d{10}$",
                title: "Phone number must be 10 digits",
                name: 'Phone',
                value: form.Phone,
                onChange: handleFormChange
              }) // Input for phone with validation
            ),
            React.createElement(
              'div',
              { className: "contact_page_form_input_box" },
              React.createElement('input', {
                type: "email",
                placeholder: "Enter Email",
                required: true,
                minLength: 10,
                maxLength: 50,
                name: 'Email',
                value: form.Email,
                onChange: handleFormChange
              }) // Input for email with validation
            ),
            React.createElement('button', { className: "contact_page_btn", type: "submit", disabled: isButtonDisabled }, "Submit"),
            React.createElement('p', null, "Your escape to paradise starts with contact us now!") // Submit button and message
          )
        )
      )
    ),
    React.createElement('embed', {
      src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14014.237297314034!2d77.30112617134519!3d28.582992754344932!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce459c07192ab%3A0x159daf20d07f9e94!2sMountForest!5e0!3m2!1sen!2sin!4v1717226493948!5m2!1sen!2sin",
      title: "MountForest",
      allowFullScreen: true,
      loading: "lazy",
      referrerPolicy: "no-referrer-when-downgrade",
      className: 'contact_map'
    }) // Embedded Google Map for location
  );
}

export default Contact; // Exporting the component for use in other parts of the application
