import React, { useEffect, useState } from "react";
import { TfiClose } from "react-icons/tfi";
import './fixappoitment.css';

const Fixappoitment = () => {
    // State to control the visibility of the form
    const [isFormVisible, setIsFormVisible] = useState(false);
    // State to control the visibility of the button
    const [isButtonVisible, setIsButtonVisible] = useState(false);

    // State to manage form inputs
    const [form, setForm] = useState({
        Name: '',
        Phone: '',
        Email: '',
        Date: '',
        Time: ''
    });

    // State to control if the submit button should be disabled
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    // Effect to check if all form fields are filled and set the submit button state accordingly
    useEffect(() => {
        const allFieldsFilled = Object.values(form).every(field => field.trim() !== '');
        setIsButtonDisabled(!allFieldsFilled);
    }, [form]);

    // Function to handle form input changes
    const handleFormContact = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });
    };

    // Function to toggle the visibility of the form
    const toggleFormVisibility = () => {
        setIsFormVisible(!isFormVisible);
    };

    // Function to get today's date in the required format
    const FixappoitmentgetTodayDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    // Effect to handle scroll event and show/hide the button based on scroll position
    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            setIsButtonVisible(scrollPosition >= 300);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Function to handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        Object.entries(form).forEach(([key, value]) => {
            formData.append(key, value);
        });
        formData.append("Date", new Date().toISOString().substring(0, 10));

        try {
            const response = await fetch("https://script.google.com/macros/s/AKfycbyFHEZIR4-iSKfsoIRPHPoy_C2gwuuf1M5ycpndvHjPhMgRQ0INWbODZiyIarBq5hPv/exec", {
                method: "POST",
                body: formData
            });
            if (response.ok) {
                alert('Your Form successfully submitted');
                setForm({
                    Name: '',
                    Phone: '',
                    Email: '',
                    Date: '',
                    Time: ''
                });
                setIsFormVisible(false);
            } else {
                alert('Form submission failed');
            }
        } catch (error) {
            alert('Your Form successfully submitted');
        }
    };

    return (
        React.createElement(
            React.Fragment,
            null,
            // Render button to fix appointment
            isButtonVisible && React.createElement(
                'button',
                { className: 'fix', onClick: toggleFormVisibility },
                'Fix an appointment'
            ),
            // Render form if it is visible
            isFormVisible && React.createElement(
                React.Fragment,
                null,
                React.createElement('div', { className: "overlay", onClick: toggleFormVisibility }),
                React.createElement(
                    'section',
                    { className: 'Fix_appoitnment_container' },
                    React.createElement(
                        'form',
                        { className: "Fix_appoitnment_form", onSubmit: handleSubmit },
                        React.createElement('span', { className: "Fix_appoitnment_close_btn", onClick: toggleFormVisibility }, React.createElement(TfiClose)),
                        React.createElement('center', null, React.createElement('b', null, 'Fix an appointment')),
                        React.createElement('br'),
                        React.createElement('input', {
                            type: "hidden",
                            name: "Date",
                            value: new Date().toISOString().substring(0, 10),
                            readOnly: true
                        }),
                        React.createElement(
                            'div',
                            { className: 'Fix_appoitnment_input_box' },
                            React.createElement('input', {
                                type: 'text',
                                placeholder: 'Enter Name',
                                name: "Name",
                                pattern: "^[a-zA-Z]*$",
                                title: "Only letters and spaces allowed",
                                value: form.Name,
                                required: true,
                                minLength: 3,
                                maxLength: 30,
                                className: 'Fix_appoitnment_input',
                                onChange: handleFormContact
                            })
                        ),
                        React.createElement(
                            'div',
                            { className: 'Fix_appoitnment_input_box' },
                            React.createElement('input', {
                                type: 'tel',
                                placeholder: 'Enter Phone',
                                name: "Phone",
                                pattern: "^\\d{10}$",
                                title: "Phone number must be 10 digits",
                                value: form.Phone,
                                required: true,
                                minLength: 10,
                                maxLength: 10,
                                className: 'Fix_appoitnment_input',
                                onChange: handleFormContact
                            })
                        ),
                        React.createElement(
                            'div',
                            { className: 'Fix_appoitnment_input_box' },
                            React.createElement('input', {
                                type: 'email',
                                placeholder: 'Enter Email',
                                name: "Email",
                                value: form.Email,
                                required: true,
                                minLength: 10,
                                maxLength: 50,
                                className: 'Fix_appoitnment_input',
                                onChange: handleFormContact
                            })
                        ),
                        React.createElement(
                            'div',
                            { className: 'Fix_appoitnment_input_box' },
                            React.createElement('input', {
                                type: 'date',
                                name: "Date",
                                required: true,
                                min: FixappoitmentgetTodayDate(),
                                className: 'Fix_appoitnment_input',
                                onChange: handleFormContact
                            })
                        ),
                        React.createElement(
                            'div',
                            { className: 'Fix_appoitnment_input_box' },
                            React.createElement(
                                'select',
                                {
                                    name: 'Time',
                                    required: true,
                                    className: 'Fix_appoitnment_input',
                                    onChange: handleFormContact
                                },
                                React.createElement('option', { value: "" }, "Preferred Time"),
                                React.createElement('option', { value: "9:30 AM - 10:30 AM" }, "9:30 AM - 10:30 AM"),
                                React.createElement('option', { value: "11:00 AM - 1:00 PM" }, "11:00 AM - 1:00 PM"),
                                React.createElement('option', { value: "2:00 PM - 3:00 PM" }, "2:00 PM - 3:00 PM"),
                                React.createElement('option', { value: "3:00 PM - 4:00 PM" }, "3:00 PM - 4:00 PM"),
                                React.createElement('option', { value: "4:00 PM - 5:00 PM" }, "4:00 PM - 5:00 PM"),
                                React.createElement('option', { value: "6:00 PM - 7:00 PM" }, "6:00 PM - 7:00 PM")
                            )
                        ),
                        React.createElement(
                            'div',
                            { className: 'Fix_appoitnment_btn_box' },
                            React.createElement(
                                'button',
                                {
                                    type: "submit",
                                    className: "Fix_appoitnment_btn",
                                    disabled: isButtonDisabled
                                },
                                'BOOK NOW'
                            )
                        ),
                        React.createElement('br'),
                        React.createElement('p', null, "Fill the form & let connect with our project expert.")
                    )
                )
            )
        )
    );
}

export default Fixappoitment;
