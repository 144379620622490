import React,{ useEffect, useState } from "react";
// Importing necessary modules and styles
import './footeraboveform.css';

// Define the FooterAboveForm component as a function
const FooterAboveForm = () => {
    // State for form fields and button state
    const [form, setForm] = useState({
        Name: '',
        Phone: '',
        Email: '',
        Message: ''
    });

    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    // Effect to update button disable state based on form completeness
    useEffect(() => {
        const allFieldsFilled = Object.values(form).every(field => field.trim() !== '');
        setIsButtonDisabled(!allFieldsFilled);
    }, [form]);

    // Function to handle input changes
    const handleInputChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });
    };

    // Function to handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        Object.entries(form).forEach(([key, value]) => {
            formData.append(key, value);
        });
        formData.append("Date", new Date().toISOString().substring(0, 10));

        try {
            const response = await fetch("https://script.google.com/macros/s/AKfycbzGmsr4lf4clHWpzim061MC48WKtQfzmEsiS3muCXiIm0mUNrCYVcQ5pEVeoW65hDcK/exec", {
                method: "POST",
                body: formData
            });
            if (response.ok) {
                alert('Your Form successfully submitted');
                setForm({ Name: '', Phone: '', Email: '', Message: '' });
            } else {
                alert('Form submission failed');
            }
        } catch (error) {
            alert('Form submission failed');
        }
    };

    // Return the structure of the FooterAboveForm component
    return (
        // Using main as the root element with class Footer_Above_container
        React.createElement('main', { className: 'Footer_Above_container' },
            // Div for form description
            React.createElement('div', { className: 'Footer_From_Description' },
                React.createElement('h3', null, 'Contact Us'),
                React.createElement('br'),
                React.createElement('p', null, 'If you have any questions, concerns, or feedback, please let us know. Our team will review your message and get back to you as soon as possible. We value your input and strive to provide excellent service.')
            ),
            // Section for form container
            React.createElement('section', { id: 'Footer_Above_From_container' },
                // Form element with onSubmit handler and class Footer_Above_From_box
                React.createElement('form', { onSubmit: handleSubmit, className: 'Footer_Above_From_box' },
                    // Hidden input for Date
                    React.createElement('input', {
                        type: 'hidden',
                        name: 'Date',
                        value: new Date().toISOString().substring(0, 10),
                        readOnly: true
                    }),
                    // Input for Name
                    React.createElement('div', { className: 'Footer_Above_From_input' },
                        React.createElement('input', {
                            type: 'text',
                            placeholder: 'Enter Name',
                            required: true,
                            minLength: 3,
                            maxLength: 30,
                            name: 'Name',
                            pattern: '^[a-zA-Z]*$',
                            title: 'Only letters and spaces allowed',
                            value: form.Name,
                            onChange: handleInputChange,
                            className: 'Footer_Above_input'
                        })
                    ),
                    // Input for Phone
                    React.createElement('div', { className: 'Footer_Above_From_input' },
                        React.createElement('input', {
                            type: 'tel',
                            placeholder: 'Enter Phone',
                            required: true,
                            pattern: '^{10}$',
                            title: 'Phone number must be 10 digits',
                            name: 'Phone',
                            value: form.Phone,
                            onChange: handleInputChange,
                            className: 'Footer_Above_input'
                        })
                    ),
                    // Input for Email
                    React.createElement('div', { className: 'Footer_Above_From_input' },
                        React.createElement('input', {
                            type: 'email',
                            placeholder: 'Enter Email',
                            required: true,
                            minLength: 10,
                            maxLength: 50,
                            name: 'Email',
                            value: form.Email,
                            onChange: handleInputChange,
                            className: 'Footer_Above_input'
                        })
                    ),
                    // Textarea for Message
                    React.createElement('div', { className: 'Footer_Above_From_textarea' },
                        React.createElement('textarea', {
                            placeholder: 'Enter Message...',
                            required: true,
                            minLength: 3,
                            maxLength: 500,
                            name: 'Message',
                            value: form.Message,
                            onChange: handleInputChange,
                            className: 'Footer_Above_textarea'
                        })
                    ),
                    // Submit button
                    React.createElement('button', {
                        type: 'submit',
                        disabled: isButtonDisabled,
                        id: 'Footer_Above_Contcat_Submit_but'
                    }, 'Submit')
                )
            )
        )
    );
};

// Exporting the FooterAboveForm component as default
export default FooterAboveForm;
