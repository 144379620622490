import './about.css'; // Importing the CSS file for styling
import { Helmet } from "react-helmet"; // Importing Helmet for managing the document head
import BgAbout from '../Images/BgAbout.webp'; // Importing the background image
import FooterAboveForm from '../FooterAboveForm/FooterAboveForm'; // Importing the FooterAboveForm component
import React from 'react'; // Importing React

// Defining the About functional component
function About() {
    return React.createElement(
        'main',
        null,
        React.createElement(
            Helmet,
            null,
            React.createElement('meta', { charSet: "utf-8" }), // Setting the character encoding
            React.createElement('meta', { name: "viewport", content: "width=device-width, initial-scale=1" }), // Setting the viewport for responsiveness
            React.createElement('title', null, "About Us | MountForest | Real Estate"), // Setting the page title
            React.createElement('meta', {
                name: "description",
                content: "Mountforest Promoters & Developer is a real estate and construction consulting company we specialize in catering luxurious mountain properties and holiday homes with excellent services and quality results to clients."
            }), // Setting the meta description
            React.createElement('meta', {
                name: "keywords",
                content: "mountforest, real estate company, service apartment, luxury villas, holiday homes, second homes hills, Himalayan views, properties, property, Second Income Source"
            }), // Setting the meta keywords
        ),
        React.createElement(
            'div',
            { className: 'Web_About_Container' }, // Main container for the about section
            React.createElement('img', {
                src: BgAbout,
                alt: 'Property In Mountforest',
                className: 'about_background_image'
            }), // Background image
            React.createElement('h1', { id: 'Web_About_heading' }, "About Us"), // Heading
            React.createElement('br', null), // Line break
            React.createElement(
                'p',
                { id: "Web_About_Paragraph" },
                "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0", // Non-breaking spaces for indentation
                React.createElement('b', null, "Mountforest Promoters & Developer"),
                " is a real estate and construction consulting company we specialize in catering luxurious mountain properties and holiday homes with excellent services and quality results to clients. We specialize in the development of properties in mountains, as well as in the management of existing assets. Our team of experienced professionals is trained to assess and identify key areas for improvement in order to maximize benefits. We stay up to date on the latest industry trends and regulations in order to provide the most comprehensive services possible.",
                React.createElement('br', null),
                React.createElement('br', null),
                "We are leading construction consulting firm that is committed to helping in develop and manage projects and properties with decades of experience in the industry, we have the expertise and resources to handle the most complex projects and provide the highest quality of services. Our team of professionals is dedicated to providing our clients with the best possible solutions for their mountain properties.",
                React.createElement('br', null),
                React.createElement('br', null),
                "We understand that every person has different mindset, so our team works closely with our clients to understand their unique needs and goals. Our goal is to create the most efficient and effective solutions for each client. We are committed to providing the best customer service and always strive to exceed their expectations.",
                React.createElement('br', null),
                React.createElement('br', null),
                "At Mountforest Promoters & Developer, we pride ourselves on offering tailored solutions to meet the needs of each and every client. We are dedicated to providing personal attention and open communication throughout the entire process. Our team is committed to meeting your needs and exceeding your expectations. We invite you to contact us today and learn more about how can we help you to find your mountain property with serene view.",
                React.createElement('b', null, "WE FULFILL EVERY DESIRE OF HEART.")
            )
        ),
        React.createElement(FooterAboveForm, null) // Rendering the FooterAboveForm component
    );
}

export default About; // Exporting the About component as the default export
