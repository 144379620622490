import React from "react";
import './Home.css';
import IncomeImg from '../Images/Income.webp';

const SecondIncomeHomePage = () => {
  return React.createElement(
    'div',
    { className: 'Second_Income_Home_Page_Container' },
    React.createElement(
      'div',
      { id: 'Second_Income_Img_Container' },
      React.createElement('h2', { id: 'Second_Income_header' }, 'Luxurious Holiday Homes with Second Income Source'),
      React.createElement('img', { src: IncomeImg, alt: 'Second Income Source', className: 'Second_Income_Img' }),
      React.createElement(
        'p',
        { id: 'Second_Income_perag' },
        "Yes, you heard right ‘Ab Ghar bhi aur Basar Bhi’. We provide you with a steady <strong>second income</strong> and a peaceful getaway? Look no further! Our exclusive <strong>holiday homes</strong> nestled in the picturesque mountains of <strong>Uttarakhand</strong> offer you the perfect blend of financial benefits and natural beauty."
      ),

      React.createElement('b', { className: 'Second_Income_head' }, 'Why Choose Our Holiday Homes?'),

      React.createElement(
        'p',
        { className: 'Second_Income_perag' },
        '\u25CF ',
        React.createElement('b', null, 'Assured Second Income :'),
        ' Invest in our holiday homes and enjoy a guaranteed <strong>second income</strong>.',
        React.createElement('br'),
        '\u25CF ',
        React.createElement('b', null, 'Holiday Benefits :'),
        ' Your investment doubles as a vacation spot where you can escape and unwind amidst <strong>nature\'s serenity</strong>.',
        React.createElement('br'),
        '\u25CF ',
        React.createElement('b', null, 'Property Management :'),
        ' We take care of your property at our expense. From maintenance to management, we handle everything, ensuring your home is always in top condition.'
      ),

      React.createElement('b', { className: 'Second_Income_head' }, 'Invest Smartly in Uttarakhand :'),

      React.createElement(
        'p',
        { className: 'Second_Income_perag' },
        '\u25CF ',
        React.createElement('b', null, 'Scenic Locations :'),
        ' Our properties are located in the most tranquil and beautiful parts of <strong>Uttarakhand</strong>, offering breathtaking views and a serene environment.',
        React.createElement('br'),
        '\u25CF ',
        React.createElement('b', null, 'Hassle-Free Ownership :'),
        ' With our comprehensive property management services, you can enjoy a hassle-free ownership experience.'
      ),

      React.createElement('b', { className: 'Second_Income_head' }, 'Make Your Investment Work for You:'),

      React.createElement(
        'p',
        { className: 'Second_Income_perag' },
        '\u25CF Enjoy the beauty and tranquility of the <strong>mountains</strong>.',
        React.createElement('br'),
        '\u25CF Earn a steady <strong>income</strong> without any extra effort.',
        React.createElement('br'),
        '\u25CF Relax and let us manage your property for you.'
      ),

      React.createElement('b', { className: 'Second_Income_head' }, 'Discover the Best of Both Worlds :'),

      React.createElement(
        'p',
        { className: 'Second_Income_perag' },
        'Invest in our holiday homes and experience the joy of having a <strong>home away from home</strong>. Reap the financial benefits and cherish the moments spent in <strong>nature\'s embrace</strong>.'
      ),

      React.createElement('b', { className: 'Second_Income_head' }, 'Contact Us Today :'),

      React.createElement(
        'p',
        { className: 'Second_Income_perag' },
        'Start your journey towards a smart <strong>investment</strong> and a peaceful retreat. Contact us now to learn more about our holiday homes in <strong>Uttarakhand</strong> and secure your spot in paradise.'
      )
    )
  );
}

export default SecondIncomeHomePage;
